import SVG from 'react-inlinesvg';
import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { px } from '@gilbarbara/components';

import { theme } from '~/modules/theme';

type names =
  | 'bolt'
  | 'broken-note'
  | 'collection'
  | 'heart-o'
  | 'heart'
  | 'pause'
  | 'play'
  | 'playlist';

interface Props {
  fade?: boolean;
  name: names;
  size?: number;
  space?: boolean;
  spin?: boolean;
  title?: string | null;
}

const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
`;

const fadeAnimation = keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
  }
`;

const Wrapper = styled.span<Pick<Props, 'fade' | 'size' | 'space' | 'spin'>>(props => {
  const { fade, size, space, spin } = props;
  let animation;

  if (spin) {
    animation = css`
      animation: ${spinAnimation} 1.2s infinite linear;
    `;
  } else if (fade) {
    animation = css`
      animation: ${fadeAnimation} 1.2s infinite linear;
    `;
  }

  return css`
    animation: ${animation};
    display: inline-flex;
    font-size: ${size ? px(size) : 'inherit'};
    margin-right: ${space ? theme.spacing.xxs : undefined};
  `;
});

function Icon({ name, title, ...props }: Props) {
  return (
    <Wrapper {...props}>
      <SVG src={`/icons/${name}.svg`} />
    </Wrapper>
  );
}

export default Icon;
