/* eslint-disable camelcase */

import { request } from '@gilbarbara/helpers';
import { Musicbot } from '@gilbarbara/services-types';

import { API_URL, spotify } from '~/config';

/**
 * Parse request error.
 */
export function parseResponseError(message: string = ''): string {
  if (!message || message.includes('ajax error')) {
    return 'Algum erro ocorreu. Por favor, verifique sua conexão ou tente novamente.';
  }

  return message;
}

export async function getPlaylistsRequest(accessToken: string) {
  return request<Musicbot.Playlist[]>(`${API_URL}/playlists`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
}

export async function getPlaylistRequest(accessToken: string, id: string) {
  return request<Musicbot.Playlist>(`${API_URL}/playlists/${id}`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
}

export async function removePlaylistRequest(accessToken: string, id: string) {
  return request<void>(`${API_URL}/playlists/${id}`, {
    method: 'DELETE',
    headers: { Authorization: `Bearer ${accessToken}` },
  });
}

export async function removePlaylistTrackRequest(accessToken: string, id: string, trackId: string) {
  return request<void>(`${API_URL}/tracks/${id}/${trackId}`, {
    method: 'DELETE',
    headers: { Authorization: `Bearer ${accessToken}` },
  });
}

export async function savePlaylistRequest(accessToken: string, body: Musicbot.PlaylistCreate) {
  return request<Musicbot.Playlist>(`${API_URL}/playlists`, {
    method: 'POST',
    headers: { Authorization: `Bearer ${accessToken}` },
    body,
  });
}

export async function updatePlaylistRequest(
  accessToken: string,
  id: string,
  body: Musicbot.PlaylistUpdate,
) {
  return request<void>(`${API_URL}/playlists/${id}`, {
    method: 'PATCH',
    headers: { Authorization: `Bearer ${accessToken}` },
    body,
  });
}

export async function refreshTokenRequest(refreshToken: string) {
  return request(`${API_URL}/refresh`, {
    method: 'POST',
    body: { refreshToken },
  });
}

export async function tokenRequest(code: string) {
  return request(`${API_URL}/token`, {
    method: 'POST',
    body: { code, redirectUri: spotify.redirectUri },
  });
}
