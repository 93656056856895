import alerts from './alerts';
import app from './app';
import player from './player';
import playlists from './playlists';
import spotify from './spotify';
import user from './user';

export const { alertHide, alertShow } = alerts.actions;

export const {
  addToGenerator,
  navigateTo,
  removeFromGenerator,
  resetGenerator,
  resetHref,
  resetUri,
  saveGeneratorOptions,
  setBreakpoint,
} = app.actions;

export const { playerReset, playerUpdate } = player.actions;

export const {
  exportPlaylist,
  exportPlaylistFailure,
  exportPlaylistSuccess,
  generatePlaylist,
  generatePlaylistSuccess,
  getPlaylist,
  getPlaylistFailure,
  getPlaylists,
  getPlaylistsFailure,
  getPlaylistsSuccess,
  getPlaylistSuccess,
  getRecommendations,
  getRecommendationsFailure,
  getRecommendationsSuccess,
  removeFromPlaylist,
  removeFromPlaylistFailure,
  removeFromPlaylistSuccess,
  removePlaylist,
  removePlaylistFailure,
  removePlaylistSuccess,
  resetPlaylistsCache,
  savePlaylist,
  savePlaylistFailure,
  savePlaylistSuccess,
  updatePlaylist,
  updatePlaylistFailure,
  updatePlaylistSuccess,
} = playlists.actions;

export const {
  getAudioFeatures,
  getAudioFeaturesFailure,
  getAudioFeaturesSuccess,
  getLibrary,
  getLibraryFailure,
  getLibrarySuccess,
  getTopArtists,
  getTopArtistsFailure,
  getTopArtistsSuccess,
  getTopTracks,
  getTopTracksFailure,
  getTopTracksSuccess,
  getTracksStatus,
  getTracksStatusFailure,
  getTracksStatusSuccess,
  removeTrack,
  removeTrackFailure,
  removeTrackSuccess,
  resetAudioFeatures,
  saveTrack,
  saveTrackFailure,
  saveTrackSuccess,
  updateTrackStatus,
} = spotify.actions;

export const {
  getAccessToken,
  getAccessTokenFailure,
  getAccessTokenSuccess,
  getUserData,
  getUserDataFailure,
  getUserDataSuccess,
  login,
  logout,
  refreshToken,
  refreshTokenFailure,
  refreshTokenSuccess,
} = user.actions;
