import { ReactNode } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { px } from '@gilbarbara/components';

import { headerHeight, playerHeight, theme } from '~/modules/theme';

interface Props {
  children: ReactNode;
  isAuthenticated: boolean;
  withPlayer: boolean;
}

const App = styled.div<Props>(props => {
  const { isAuthenticated, withPlayer } = props;

  return css`
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    /* stylelint-disable-next-line */
    ${isAuthenticated &&
    css`
      padding-top: ${px(headerHeight.sm)};

      @media (min-width: ${theme.breakpoints.md}) {
        padding-top: ${px(headerHeight.md)};
      }
    `}

    /* stylelint-disable-next-line */
    ${withPlayer &&
    css`
      padding-bottom: ${playerHeight};
    `}
  `;
});

export default App;
