import styled from '@emotion/styled';

const Main = styled.main`
  display: flex;
  flex: 1;
  flex-direction: column;

  > * {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
  }
`;

Main.defaultProps = {
  // @ts-expect-error styled doens't support dataset
  'data-component-name': 'Main',
};

export default Main;
