import { logger } from '@gilbarbara/helpers';

declare let gtag: any;

/**
 * Send a page view to analytics.
 */
export function pageView(
  title: string = document.title,
  uri: string = `${window.location.pathname}${window.location.search}`,
) {
  if (typeof gtag !== 'undefined') {
    gtag('event', 'page_view', {
      page_location: uri,
      page_title: title,
    });
  }

  logger('pageview', title, uri, { typeColor: '#007bff' });
}

/**
 * Send an event to analytics.
 */
export function event(
  category: string,
  action: string = 'click',
  label: string | undefined = undefined,
  value: number = 1,
) {
  if (!category) {
    throw new Error('Missing category');
  }

  if (typeof gtag !== 'undefined') {
    gtag('event', action, {
      event_category: category,
      event_label: label,
      value,
    });
  }

  logger('event', `${category}/${action}`, { label, value }, { typeColor: '#f04' });
}
