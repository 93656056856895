import { ComponentProps } from 'react';
import {
  Link as RouterLink,
  LinkProps,
  NavLink as RouterNavLink,
  useLinkClickHandler,
} from 'react-router-dom';
import styled from '@emotion/styled';
import { Button } from '@gilbarbara/components';

interface Props extends ComponentProps<typeof Button> {
  preventScrollReset?: LinkProps['preventScrollReset'];
  relative?: LinkProps['relative'];
  reloadDocument?: LinkProps['reloadDocument'];
  replace?: LinkProps['replace'];
  state?: LinkProps['state'];
  to: LinkProps['to'];
}

export function ButtonLink(props: Props) {
  const { preventScrollReset, relative, reloadDocument, replace, state, to, ...rest } = props;
  const handleClick = useLinkClickHandler<HTMLButtonElement>(to, {
    preventScrollReset,
    relative,
    replace,
    state,
  });

  return <Button as="a" {...rest} onClick={handleClick} />;
}

export const Link = styled(RouterLink)`
  display: inline-flex;
`;

export const NavLink = styled(RouterNavLink)`
  align-items: center;
  display: inline-flex;
`;
