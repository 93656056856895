import { SpotifyTrack } from 'react-spotify-web-playback';
import { ASYNC_STATUS, now } from '@gilbarbara/helpers';
import { Musicbot } from '@gilbarbara/services-types';
import { AnyObject, AsyncFlow, PlainObject } from '@gilbarbara/types';
import { createAction } from '@reduxjs/toolkit';
import { REHYDRATE } from 'redux-persist';

import { RootState } from '~/types';

export function actionBody<T = any, M extends AnyObject = AnyObject>(
  payload: T,
  meta: PlainObject<M>,
): { meta: M; payload: T };
export function actionBody<T = any>(payload: T): { meta: never; payload: T };

export function actionBody<T = any>(payload: T, meta?: any) {
  return { payload, meta };
}

export function getMinutes(milliseconds: number): string {
  if (typeof milliseconds === 'undefined') {
    return '--';
  }

  const time = Math.round(milliseconds / 1000);
  const minutes = Math.floor(time / 60);
  const seconds = time - minutes * 60;

  return `${stringPadLeft(minutes)}:${stringPadLeft(seconds)}`;
}

export function getPitchClass(key: number) {
  const scale = ['C', 'C#', 'D', 'D♯', 'E', 'F', 'F♯', 'G', 'G♯', 'A', 'A♯', 'B'];

  if (!key || key < 0) {
    return '--';
  }

  return scale[key];
}

export function getTrackUri(track: SpotifyTrack | Musicbot.TrackBase | null) {
  if (!track) {
    return '';
  }

  if ('uri' in track) {
    return track.uri;
  }

  return `spotify:track:${track.spotifyId}`;
}

/**
 * Check if cache is valid
 */
export function hasValidCache(lastUpdated: number, max = 10): boolean {
  if (!navigator.onLine) {
    return true;
  }

  return lastUpdated + max * 60 > now();
}

export function isPending(state?: AsyncFlow) {
  return state?.status === ASYNC_STATUS.PENDING;
}

export function stringPadLeft(value: number, padding: string = '0', size: number = 2) {
  return (new Array(size + 1).join(padding) + value).slice(-size);
}

export const rehydrateAction = createAction<RootState | undefined>(REHYDRATE);
