import { omit, sortByPrimitive } from '@gilbarbara/helpers';
import { Musicbot } from '@gilbarbara/services-types';
import { PayloadAction } from '@reduxjs/toolkit';
import is from 'is-lite';
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';

import {
  getPlaylistRequest,
  getPlaylistsRequest,
  parseResponseError,
  removePlaylistRequest,
  removePlaylistTrackRequest,
  savePlaylistRequest,
  updatePlaylistRequest,
} from '~/modules/api';
import {
  addTracksToPlaylist,
  createPlaylist,
  getRecommendations as getSpotifyRecommendations,
  getTracks,
  parseSpotifyTracks,
} from '~/modules/spotify';

import {
  alertShow,
  exportPlaylist,
  exportPlaylistFailure,
  exportPlaylistSuccess,
  generatePlaylist,
  generatePlaylistSelector,
  generatePlaylistSuccess,
  getPlaylist,
  getPlaylistFailure,
  getPlaylists,
  getPlaylistsFailure,
  getPlaylistsSuccess,
  getPlaylistSuccess,
  getRecommendations,
  getRecommendationsFailure,
  getRecommendationsSuccess,
  navigateTo,
  removeFromPlaylist,
  removeFromPlaylistFailure,
  removeFromPlaylistSuccess,
  removePlaylist,
  removePlaylistFailure,
  removePlaylistSuccess,
  resetGenerator,
  resetPlaylistsCache,
  savePlaylist,
  savePlaylistFailure,
  savePlaylistSuccess,
  updatePlaylist,
  updatePlaylistFailure,
  updatePlaylistSuccess,
  userSelector,
} from '~/services';

import { CallReturnType, GeneratePlaylist, RootState, UserData, UserState } from '~/types';

function* exportPlaylistTracks(spotifyId: string) {
  const { playlists }: RootState = yield select();
  const playlist = playlists.get.data;

  yield !playlist
    ? put(alertShow('Export Playlist not found', { type: 'error' }))
    : call(addTracksToPlaylist, spotifyId, playlist);
}

function* exportPlaylistSaga({ payload }: ReturnType<typeof exportPlaylist>) {
  const { playlists, user }: RootState = yield select();
  const playlist = playlists.get.data;

  try {
    if (!playlist || payload !== playlist?.id) {
      throw new Error('Playlist not found');
    }

    const data: CallReturnType<typeof createPlaylist> = yield call(
      createPlaylist,
      user.data?.id ?? '',
      playlist,
    );

    yield call(exportPlaylistTracks, data.body.id);

    yield put(exportPlaylistSuccess(data));

    yield put(
      updatePlaylist(data.id, {
        exported: true,
        spotifyId: data.body.id,
        url: data.body.external_urls.spotify,
      }),
    );

    yield put(alertShow('Playlist exported successfully to your Spotify account.'));
  } catch (error: any) {
    yield put(exportPlaylistFailure(error.message));
    yield put(alertShow('Export failed, try again later.', { type: 'error', timeout: 10 }));
  }
}

function* generatePlaylistSaga({ payload }: ReturnType<typeof generatePlaylist>) {
  const user: UserState<UserData> = yield select(userSelector);
  const defaultLimit = 100 - payload.options.seed_tracks.length;
  const attributes = omit(payload.seeds, 'limit', 'seed_artists', 'seed_genres', 'seed_tracks');

  try {
    yield put(
      getRecommendations({
        ...payload.seeds,
        limit: payload.seeds.limit || defaultLimit,
        market: user.data.country,
      }),
    );
    yield take(getRecommendationsSuccess.type);

    const { options, status, ...data }: GeneratePlaylist = yield select(generatePlaylistSelector);

    yield put(savePlaylist({ ...data, options: { ...options, ...attributes } }));
    const saveAction: PayloadAction<Musicbot.Playlist> = yield take(savePlaylistSuccess.type);

    yield put(generatePlaylistSuccess());

    yield put(resetGenerator());
    yield put(resetPlaylistsCache());

    yield put(navigateTo(`/playlists/${saveAction.payload.id}`));
  } catch (error: any) {
    yield put(alertShow(parseResponseError(error.message)));
  }
}

function* getPlaylistSaga({ payload }: ReturnType<typeof getPlaylist>) {
  const user: UserState<UserData> = yield select(userSelector);
  const id = is.plainObject(payload) ? (payload.id as string) : payload;

  try {
    const playlist: Musicbot.PlaylistWithTracks = yield call(
      getPlaylistRequest,
      user.auth.accessToken,
      id,
    );

    yield put(getPlaylistSuccess(playlist));
  } catch (error: any) {
    yield put(getPlaylistFailure(error.message));
  }
}

function* getPlaylistsSaga() {
  const user: UserState<UserData> = yield select(userSelector);

  try {
    const playlists: Musicbot.Playlist[] = yield call(getPlaylistsRequest, user.auth.accessToken);

    yield put(getPlaylistsSuccess(playlists.sort(sortByPrimitive('updatedAt', true))));
  } catch (error: any) {
    yield put(getPlaylistsFailure(error.message));
  }
}

function* getRecommendationsSaga({ payload }: ReturnType<typeof getRecommendations>) {
  try {
    const recommendations: SpotifyApi.RecommendationsFromSeedsResponse = yield call(
      getSpotifyRecommendations,
      payload,
    );
    let baseTracks: { tracks: SpotifyApi.TrackObjectFull[] } = { tracks: [] };
    const { options }: RootState['playlists']['generatePlaylist'] = yield select(
      generatePlaylistSelector,
    );
    const seedTracks = options?.seed_tracks;

    if (seedTracks?.length) {
      baseTracks = yield call(
        getTracks,
        seedTracks.map((d): string => d.id),
      );
    }

    yield put(
      getRecommendationsSuccess({
        tracks: parseSpotifyTracks([
          ...baseTracks.tracks,
          ...(recommendations.tracks.slice(
            0,
            100 - (seedTracks?.length || 0),
          ) as SpotifyApi.RecommendationTrackObject[]),
        ]),
      }),
    );
  } catch (error: any) {
    yield put(getRecommendationsFailure(parseResponseError(error.message)));
  }
}

function* removePlaylistSaga({ payload }: ReturnType<typeof removePlaylist>) {
  const user: UserState<UserData> = yield select(userSelector);

  try {
    yield call(removePlaylistRequest, user.auth.accessToken, payload);
    yield put(navigateTo('/playlists'));

    yield put(removePlaylistSuccess(payload));
  } catch (error: any) {
    yield put(removePlaylistFailure(error.message));
    yield put(alertShow(error, { type: 'error' }));
  }
}

function* removeTracksSaga({ payload }: ReturnType<typeof removeFromPlaylist>) {
  const user: UserState<UserData> = yield select(userSelector);

  try {
    yield call(
      removePlaylistTrackRequest,
      user.auth.accessToken,
      payload.playlistId,
      payload.spotifyId,
    );

    yield put(removeFromPlaylistSuccess(payload.playlistId, payload.spotifyId));
  } catch (error: any) {
    yield put(removeFromPlaylistFailure(error.message));
  }
}

function* savePlaylistSaga({ payload }: ReturnType<typeof savePlaylist>) {
  const user: UserState<UserData> = yield select(userSelector);

  try {
    const playlist: Musicbot.Playlist = yield call(
      savePlaylistRequest,
      user.auth.accessToken,
      payload,
    );

    yield put(savePlaylistSuccess(playlist));
  } catch (error: any) {
    yield put(savePlaylistFailure(error.message));
  }
}

function* updatePlaylistSaga({ payload }: ReturnType<typeof updatePlaylist>) {
  const user: UserState<UserData> = yield select(userSelector);

  try {
    const response: Partial<Musicbot.Playlist> = yield call(
      updatePlaylistRequest,
      user.auth.accessToken,
      payload.id,
      payload.data,
    );

    yield put(updatePlaylistSuccess(response));
  } catch (error: any) {
    yield put(updatePlaylistFailure(error.message));
  }
}

export default function* root() {
  yield all([
    takeLatest(exportPlaylist.type, exportPlaylistSaga),
    takeLatest(generatePlaylist.type, generatePlaylistSaga),
    takeLatest(getPlaylist.type, getPlaylistSaga),
    takeLatest(getPlaylists.type, getPlaylistsSaga),
    takeLatest(getRecommendations.type, getRecommendationsSaga),
    takeLatest(removeFromPlaylist.type, removeTracksSaga),
    takeLatest(removePlaylist.type, removePlaylistSaga),
    takeLatest(savePlaylist.type, savePlaylistSaga),
    takeLatest(updatePlaylist.type, updatePlaylistSaga),
  ]);
}
