import { ASYNC_STATUS } from '@gilbarbara/helpers';
import { Musicbot } from '@gilbarbara/services-types';
import { createSlice, original, PayloadAction } from '@reduxjs/toolkit';
import produce from 'immer';

import { actionBody, rehydrateAction } from '~/modules/helpers';

import { UserData, UserState } from '~/types';

export const userState: UserState = {
  accessToken: {
    message: '',
    status: ASYNC_STATUS.IDLE,
  },
  auth: {
    accessToken: '',
    code: '',
    expiresAt: 0,
    refreshToken: '',
    scope: [],
    state: undefined,
  },
  data: null,
  getUserData: {
    message: '',
    status: ASYNC_STATUS.IDLE,
  },
  isAuthenticated: false,
  refreshToken: {
    message: '',
    status: ASYNC_STATUS.IDLE,
  },
};

function cleanupState(state: UserState) {
  return produce(state, draft => {
    draft.accessToken.status = ASYNC_STATUS.IDLE;
    draft.getUserData.status = ASYNC_STATUS.IDLE;
    draft.refreshToken.status = ASYNC_STATUS.IDLE;
  });
}

export default createSlice({
  name: 'user',
  initialState: userState,
  extraReducers: builder => {
    builder.addCase(rehydrateAction, (draft, { payload }) => {
      return cleanupState({ ...original(draft), ...payload?.user } as UserState);
    });
  },
  reducers: {
    getAccessToken: draft => {
      draft.accessToken.message = '';
      draft.accessToken.status = ASYNC_STATUS.PENDING;
    },
    getAccessTokenSuccess: {
      reducer: (draft, { payload }: PayloadAction<Musicbot.UserCredentials>) => {
        draft.accessToken.status = ASYNC_STATUS.SUCCESS;
        draft.auth = Object.assign(draft.auth, payload);
      },
      prepare: (tokens: Musicbot.UserCredentials) => actionBody(tokens),
    },
    getAccessTokenFailure: (draft, { payload }: PayloadAction<string>) => {
      draft.accessToken.message = payload;
      draft.accessToken.status = ASYNC_STATUS.ERROR;
    },
    getUserData: draft => {
      draft.getUserData.message = '';
      draft.getUserData.status = ASYNC_STATUS.PENDING;
    },
    getUserDataSuccess: {
      reducer: (draft, { payload }: PayloadAction<UserData>) => {
        draft.getUserData.status = ASYNC_STATUS.SUCCESS;
        draft.data = payload;
        draft.isAuthenticated = true;
      },
      prepare: (data: UserData) => actionBody(data),
    },
    getUserDataFailure: (draft, { payload }: PayloadAction<string>) => {
      draft.auth = userState.auth;
      draft.getUserData.message = payload;
      draft.getUserData.status = ASYNC_STATUS.ERROR;
      draft.isAuthenticated = false;
    },
    login: {
      reducer: (draft, { payload }: PayloadAction<string>) => {
        const searchParams = new URLSearchParams(payload);

        draft.auth.code = searchParams.get('code') ?? '';
      },
      prepare: (search: string) => actionBody(search),
    },
    logout: {
      reducer: () => {
        return userState;
      },
      prepare: (path = '/') => actionBody(path),
    },
    refreshToken: draft => {
      draft.refreshToken.message = '';
      draft.refreshToken.status = ASYNC_STATUS.PENDING;
    },
    refreshTokenSuccess: {
      reducer: (draft, { payload }: PayloadAction<Musicbot.UserCredentials>) => {
        draft.refreshToken.status = ASYNC_STATUS.SUCCESS;
        draft.auth = Object.assign(draft.auth, payload);
      },
      prepare: (tokens: Musicbot.UserCredentials) => actionBody(tokens),
    },
    refreshTokenFailure: (draft, { payload }: PayloadAction<string>) => {
      draft.auth = userState.auth;
      draft.refreshToken.message = payload;
      draft.refreshToken.status = ASYNC_STATUS.ERROR;
      draft.isAuthenticated = false;
    },
  },
});
