import { useMount } from 'react-use';

import { pageView } from '~/modules/analytics';

import { useUser } from '~/services';

import Empty from '~/components/Empty';
import Page from '~/components/Page';

function NotFound() {
  const { isAuthenticated } = useUser();

  useMount(() => {
    pageView(`Not Found — ${isAuthenticated ? 'Logged' : 'Anonymous'}`);
  });

  return (
    <Page centered name="404">
      <Empty isAuthenticated={isAuthenticated} showLink />
    </Page>
  );
}

export default NotFound;
