import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '~/types';

const selectSelf = (state: RootState) => state;

export const alertsSelector = createSelector(selectSelf, state => state.alerts.data);

export const breakpointSelector = createSelector(selectSelf, state => state.app.breakpoint);

export const favoritesSelector = createSelector(selectSelf, state => state.spotify.favorites.data);

export const generatePlaylistSelector = createSelector(
  selectSelf,
  state => state.playlists.generatePlaylist,
);

export const generatorSelector = createSelector(selectSelf, state => state.app.generator);

export const navigateSelector = createSelector(selectSelf, state => ({
  href: state.app.href,
  uri: state.app.uri,
}));

export const isFavoriteSelector = createSelector(
  [favoritesSelector, (_, id: string) => id],
  (favorites, id) => favorites.includes(id),
);

export const librarySelector = createSelector(selectSelf, state => state.spotify.library);

export const playerSelector = createSelector(selectSelf, state => state.player);

export const playerHasTracksSelector = createSelector(
  playerSelector,
  player => !!player.uris.length,
);

export const playlistsGetSelector = createSelector(selectSelf, state => state.playlists.get);

export const playlistsGetAllSelector = createSelector(selectSelf, state => state.playlists.getAll);

export const playlistsSelector = createSelector(selectSelf, state => state.playlists);

export const spotifySelector = createSelector(selectSelf, state => state.spotify);

export const topArtistsSelector = createSelector(selectSelf, state => state.spotify.topArtists);

export const topTracksSelector = createSelector(selectSelf, state => state.spotify.topTracks);

export const userSelector = createSelector(selectSelf, state => state.user);
