import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Loader } from '@gilbarbara/components';

import { pageView } from '~/modules/analytics';

import { login } from '~/services';

import Page from '~/components/Page';

function Callback() {
  const dispatch = useDispatch();
  const { search } = useLocation();

  useEffect(() => {
    pageView('Callback');

    if (search) {
      dispatch(login(search));
    }
  }, [dispatch, search]);

  return (
    <Page centered name="Callback">
      <Loader block />
    </Page>
  );
}

export default Callback;
