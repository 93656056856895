import { MouseEvent } from 'react';
import { Box, ButtonUnstyled, Container, Icon, Spacer, Text } from '@gilbarbara/components';

import { event } from '~/modules/analytics';
import { footerHeight, theme } from '~/modules/theme';

import { Link } from '~/components/Navigation';

const buttonStyles = {
  alignItems: 'center',
  justifyContent: 'center',
  height: 32,
  width: 32,
};

function Footer() {
  const handleClick = (mouseEvent: MouseEvent<HTMLButtonElement>) => {
    const { type = '', url } = mouseEvent.currentTarget.dataset;

    event('global', `share-${type}`);
    window.open(url, 'Social Share', 'width=600,height=400,resizable=no');
  };

  return (
    <Box
      as="footer"
      border={[{ side: 'top', size: 1, color: 'gray', shade: 'lighter' }]}
      data-component-name="Footer"
      flexBox
      height={footerHeight}
    >
      <Container align="center" direction="row" justify="space-between">
        <Spacer>
          <Link style={{ color: theme.white }} to="/about">
            <Text size="large">About</Text>
          </Link>
          <Link style={{ color: theme.white }} to="/privacy">
            <Text size="large">Privacy</Text>
          </Link>
        </Spacer>
        <Spacer>
          <ButtonUnstyled
            data-type="facebook"
            data-url="https://www.facebook.com/dialog/share?app_id=404732580142846&display=popup&href=https://musicbot.io&hashtag=#spotify"
            onClick={handleClick}
            shape="square"
            style={{
              ...buttonStyles,
              backgroundColor: '#fff',
              borderRadius: '50%',
              color: '#1877f2',
            }}
          >
            <Icon name="facebook" size={32} />
          </ButtonUnstyled>
          <ButtonUnstyled
            data-type="twitter"
            data-url="https://twitter.com/intent/tweet?text=Create awesome playlists with Spotify!&url=https://musicbot.io&hashtags=spotify"
            onClick={handleClick}
            shape="square"
            style={{
              ...buttonStyles,
              backgroundColor: '#1da1f2',
              borderRadius: '50%',
              color: '#fff',
            }}
          >
            <Icon name="twitter" />
          </ButtonUnstyled>
        </Spacer>
      </Container>
    </Box>
  );
}

export default Footer;
