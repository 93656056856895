import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { usePrevious } from 'react-use';

import { resetHref, resetUri, useAppNavigate } from '~/services';

function Navigate() {
  const dispatch = useDispatch();
  const { href, uri } = useAppNavigate();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const previousPathname = usePrevious(pathname);

  useEffect(() => {
    if (!previousPathname) {
      return;
    }

    const hasPathnameChanged = previousPathname !== pathname;
    const hasHrefChanged = href && href !== pathname;

    if (!hasPathnameChanged && hasHrefChanged) {
      navigate({
        pathname: href,
      });
      dispatch(resetHref());
    } else if (hasPathnameChanged && uri) {
      const element = document.querySelector(`[data-uri="${uri}"]`);

      if (element instanceof HTMLElement) {
        window.scrollTo({ top: element.offsetTop - 16, behavior: 'smooth' });
      }

      dispatch(resetUri());
    }
  }, [dispatch, href, navigate, pathname, previousPathname, uri]);

  return null;
}

export default Navigate;
