import { ComponentProps, ReactNode } from 'react';
import { H1 } from '@gilbarbara/components';

interface Props extends ComponentProps<typeof H1> {
  children: ReactNode;
}

export default function PageTitle(props: Props) {
  return <H1 {...props} textTransform="uppercase" />;
}
