import { Musicbot } from '@gilbarbara/services-types';
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';

import { refreshTokenRequest, tokenRequest } from '~/modules/api';
import { getUserData as getSpotifyUserData, setTokens } from '~/modules/spotify';

import {
  getAccessToken,
  getAccessTokenFailure,
  getAccessTokenSuccess,
  getPlaylists,
  getUserData,
  getUserDataFailure,
  getUserDataSuccess,
  login,
  logout,
  navigateTo,
  refreshToken,
  refreshTokenFailure,
  refreshTokenSuccess,
} from '~/services';

import { RootState, UserData } from '~/types';

/**
 * Get Access Token.
 */
function* getAccessTokenSaga() {
  const { user }: RootState = yield select();

  try {
    const auth: Musicbot.UserCredentials = yield call(tokenRequest, user.auth.code);

    yield put(getAccessTokenSuccess(auth));
  } catch (error: any) {
    yield put(getAccessTokenFailure(error.message));
  }
}

/**
 * Get user data
 */
function* getUserDataSaga() {
  try {
    const data: UserData = yield call(getSpotifyUserData);

    yield put(getUserDataSuccess(data));
  } catch (error: any) {
    yield put(getUserDataFailure(error.message));
  }
}

/**
 * Sign in.
 */
function* loginSaga() {
  try {
    yield put(getAccessToken());
    yield take(getAccessTokenSuccess.type);

    const { user }: RootState = yield select();

    yield call(setTokens, user.auth);

    yield put(getUserData());
    yield take(getUserDataSuccess.type);

    yield put(getPlaylists());
  } catch {
    yield put(logout());
  }
}

/**
 * SIgn out.
 */
function* logoutSaga({ payload }: ReturnType<typeof logout>) {
  yield put(navigateTo(payload));
}

/**
 * Refresh Access Token.
 */
function* refreshTokenSaga() {
  const { user }: RootState = yield select();

  try {
    const tokens: Musicbot.UserCredentials = yield call(
      refreshTokenRequest,
      user.auth.refreshToken,
    );

    yield call(setTokens, tokens);

    yield put(refreshTokenSuccess(tokens));
  } catch (error: any) {
    yield put(refreshTokenFailure(error.message));
  }
}

export default function* root() {
  yield all([
    takeLatest(getAccessToken.type, getAccessTokenSaga),
    takeLatest(getUserData.type, getUserDataSaga),
    takeLatest(login.type, loginSaga),
    takeLatest(logout.type, logoutSaga),
    takeLatest(refreshToken.type, refreshTokenSaga),
  ]);
}
