import { px, Types } from '@gilbarbara/components';
import is from 'is-lite';

import { breakpoints } from '~/modules/theme';

export function breakpointDown(breakpoint: Types.Breakpoints) {
  return px(breakpoints[breakpoint] - 1);
}

export function isTouchDevice() {
  const { userAgent } = navigator;

  if ('maxTouchPoints' in navigator) {
    return navigator.maxTouchPoints > 0;
  }

  const mQ = matchMedia?.('(pointer:coarse)');

  if (mQ?.media === '(pointer:coarse)') {
    return mQ.matches;
  }

  if ('orientation' in window) {
    return true;
  }

  return (
    /\b(blackberry|webos|iphone|iemobile)\b/i.test(userAgent) ||
    /\b(android|windows phone|ipad|ipod)\b/i.test(userAgent)
  );
}

/**
 * Get screen size breakpoint
 */
export function getBreakpoint(width = window.innerWidth): Types.Breakpoints {
  let breakpoint = 'xxs';

  /* istanbul ignore next */
  if (width >= 1920) {
    breakpoint = 'xxxl';
  } else if (width >= 1440) {
    breakpoint = 'xxl';
  } else if (width >= 1280) {
    breakpoint = 'xl';
  } else if (width >= 1024) {
    breakpoint = 'lg';
  } else if (width >= 768) {
    breakpoint = 'md';
  } else if (width >= 400) {
    breakpoint = 'sm';
  } else if (width >= 375) {
    breakpoint = 'xs';
  }

  return breakpoint as Types.Breakpoints;
}

/**
 * Return true for any screen size below a given breakpoint
 */
export function maxScreenSize(breakpoint: Types.Breakpoints | number = 'md') {
  return !minScreenSize(breakpoint);
}

/**
 * Return true for any screen size above a given breakpoint
 */
export function minScreenSize(breakpoint: Types.Breakpoints | number = 'md') {
  const width = window.innerWidth;

  if (is.number(breakpoint)) {
    return width >= breakpoint;
  }

  return width >= breakpoints[breakpoint];
}

export function setElementHeight(element: HTMLElement | null) {
  if (!element) {
    return;
  }

  element.style.height = 'auto';

  element.style.height = `${element.scrollHeight}px`;
}
