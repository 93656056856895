import createSagaMiddleware from 'redux-saga';

import createAuthMiddleware from './auth-middleware';

export const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, createAuthMiddleware()];

/* istanbul ignore next */
if (process.env.NODE_ENV === 'development') {
  const devMiddlewares = require('./middlewares-dev').default;

  middlewares.push(...devMiddlewares);
}

export default middlewares;
