import { useDispatch } from 'react-redux';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  Anchor,
  Avatar,
  Box,
  BoxProps,
  ButtonUnstyled,
  Container,
  Icon as IconComponent,
  Menu,
  MenuDivider,
  MenuItem,
  Paragraph,
  responsive,
} from '@gilbarbara/components';

import { event } from '~/modules/analytics';
import { breakpointDown, minScreenSize } from '~/modules/dom';
import { headerHeight, primaryColor, theme } from '~/modules/theme';

import { logout } from '~/services';

import Icon from '~/components/Icon';
import Logo from '~/components/Logo';
import { Link, NavLink } from '~/components/Navigation';

import { UserData, UserState } from '~/types';

interface Props {
  user: UserState<UserData>;
}

const Wrapper = styled(Box)<BoxProps>`
  background-color: ${primaryColor};
  color: ${theme.white};
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 200;

  [data-component-name='Logo'] {
    color: ${theme.white};
  }

  /* stylelint-disable-next-line */
  ${responsive({
    _: {
      height: headerHeight.sm,
    },
    md: {
      height: headerHeight.md,
    },
  })};
`;

const HeaderMenu = styled(Box)<BoxProps>(props => {
  const {
    theme: { black, breakpoints, spacing, variants, white },
  } = props;

  return css`
    align-items: center;
    display: flex;

    @media (max-width: ${breakpointDown('md')}) {
      margin-top: ${spacing.xs};
      order: 3;
      width: 100%;
    }

    @media (min-width: ${breakpoints.md}) {
      margin-left: ${spacing.md};
    }

    a {
      color: ${white};
      text-decoration: none;
      white-space: nowrap;

      /* stylelint-disable-next-line */
      ${responsive({
        _: {
          fontSize: 13,
        },
        md: {
          fontSize: 16,
        },
      })};

      &:focus {
        outline-color: ${black};
      }

      &.active {
        color: ${variants.primary.darkest.bg};
      }

      i {
        display: inline-flex;
      }

      span {
        display: inline-flex;
        text-transform: uppercase;
      }

      &:hover span {
        text-decoration: underline;
      }

      &:not(:last-child) {
        margin-right: ${spacing.sm};
      }
    }
  `;
});

const HeaderUser = styled('div')`
  margin-left: auto;

  img {
    border-radius: 4px;
    height: 32px;
    width: 32px;
  }
`;

function Header({ user }: Props) {
  const dispatch = useDispatch();

  const handleClickLogout = () => {
    event('global', 'logout');
    dispatch(logout());
  };

  return (
    <Wrapper as="header" data-component-name="Header" flexBox>
      <Container align="center" alignContent="center" direction="row" wrap="wrap">
        <Link to="/generator">
          <Logo height={minScreenSize('md') ? 32 : 26} />
        </Link>
        <HeaderMenu data-component-name="HeaderMenu">
          <NavLink to="/generator">
            <Icon name="bolt" space />
            <span>Generator</span>
          </NavLink>
          <NavLink to="/playlists">
            <Icon name="playlist" space />
            <span>Playlists</span>
          </NavLink>
          <NavLink to="/library">
            <Icon name="collection" space />
            <span>Library</span>
          </NavLink>
        </HeaderMenu>
        <HeaderUser data-component-name="HeaderUser">
          <Menu
            component={
              <Avatar
                image={user.data.images?.[0]?.url}
                name={user.data.display_name || user.data.id}
                size="sm"
                variant="white"
              />
            }
          >
            <MenuItem disabled>
              <Paragraph bold>{user.data.display_name || user.data.id}</Paragraph>
            </MenuItem>
            <MenuItem>
              <Anchor
                external
                hideDecoration
                href={`https://open.spotify.com/user/${user.data.id}`}
              >
                <IconComponent name="spotify" />
                &nbsp; Spotify profile
              </Anchor>
            </MenuItem>
            <MenuDivider />
            <MenuItem>
              <ButtonUnstyled onClick={handleClickLogout} size="mid">
                <IconComponent name="log-out" />
                &nbsp;
                <span>Logout</span>
              </ButtonUnstyled>
            </MenuItem>
          </Menu>
        </HeaderUser>
      </Container>
    </Wrapper>
  );
}

export default Header;
