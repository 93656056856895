import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@emotion/react';
import { Loader } from '@gilbarbara/components';
import { PersistGate } from 'redux-persist/lib/integration/react';

import reportWebVitals from '~/modules/reportWebVitals';
import { theme } from '~/modules/theme';

import ErrorHandler from '~/components/ErrorHandler';
import GlobalStyles from '~/components/GlobalStyles';

import Root from '~/Root';
import { persistor, store } from '~/store';

const { APP_ENV = '' } = process.env;

const container = document.getElementById('root');

/* istanbul ignore next */
if (container) {
  const root = createRoot(container);

  root.render(
    <Provider store={store}>
      <PersistGate loading={<Loader block />} persistor={persistor}>
        <HelmetProvider>
          <ThemeProvider theme={theme}>
            <ErrorBoundary FallbackComponent={ErrorHandler}>
              <Root />
            </ErrorBoundary>
            <GlobalStyles />
          </ThemeProvider>
        </HelmetProvider>
      </PersistGate>
    </Provider>,
  );
}

/* istanbul ignore next */
if (['local', 'development'].includes(APP_ENV)) {
  // eslint-disable-next-line no-console
  reportWebVitals(console.log);
}
