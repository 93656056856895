import { MouseEvent, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { event } from '~/modules/analytics';

import { addToGenerator, generatePlaylist, removeFromGenerator } from '~/services';

import { GeneratorRequest, GeneratorSeedTypes } from '~/types';

interface Dataset {
  id: string;
  key?: GeneratorSeedTypes;
  selected: 'true' | 'false';
  title: string;
  type: 'artist' | 'track';
}

export function useGeneratorClickHandlers() {
  const dispatch = useDispatch();

  const handleClickNewPlaylist = useCallback(
    (mouseEvent: MouseEvent<HTMLButtonElement>) => {
      const {
        id,
        key = 'seed_tracks',
        title,
        type = 'track',
      } = mouseEvent.currentTarget.dataset as unknown as Dataset;

      const meta: GeneratorRequest['options'] = {
        seed_artists: [],
        seed_genres: [],
        seed_tracks: [],
      };

      if (type === 'artist') {
        meta.seed_artists = [{ id, name: title }];
      } else {
        meta.seed_tracks = [{ id, name: title }];
      }

      event(type, 'generate-playlist', title);
      dispatch(generatePlaylist({ [key]: id }, meta));
    },
    [dispatch],
  );

  const handleClickSeed = useCallback(
    (mouseEvent: MouseEvent<HTMLButtonElement>) => {
      const {
        id,
        key = 'seed_tracks',
        selected,
        title,
        type = 'track',
      } = mouseEvent.currentTarget.dataset as unknown as Dataset;

      if (selected === 'true') {
        event(type, 'remove-from-generator', title);
        dispatch(removeFromGenerator(key, { id, name: title }));
      } else {
        event(type, 'add-to-generator', title);
        dispatch(addToGenerator(key, { id, name: title }));
      }
    },
    [dispatch],
  );

  return {
    handleClickNewPlaylist,
    handleClickSeed,
  };
}
