import { ReactNode, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import SpotifyPlayer, { CallbackState, Props, StylesProps, TYPE } from 'react-spotify-web-playback';
import { usePrevious, useUpdateEffect } from 'react-use';
import styled from '@emotion/styled';
import { ButtonUnstyled, Icon } from '@gilbarbara/components';
import { ASYNC_STATUS, now, request } from '@gilbarbara/helpers';

import { useGeneratorClickHandlers } from '~/hooks/useGeneratorClickHandlers';

import { getTrackUri } from '~/modules/helpers';
import { playerColor, playerHeight, primaryColor, secondaryColor } from '~/modules/theme';

import {
  navigateTo,
  playerUpdate,
  refreshToken,
  updateTrackStatus,
  useFavorites,
  useGenerator,
  usePlayer,
  usePlayerHasTracks,
  useUser,
} from '~/services';

import { store } from '~/store';
import { PlayerState } from '~/types';

const Wrapper = styled.div<{ isVisible: boolean }>`
  background-color: ${playerColor};
  bottom: 0;
  left: 0;
  min-width: 290px;
  position: fixed;
  right: 0;
  transform: translateY(${({ isVisible }) => (isVisible ? 0 : playerHeight)});
  transition: transform 0.4s ease-in-out;
  will-change: transform;
  z-index: 25;
`;

function Player() {
  const [styles, setStyles] = useState<StylesProps>();
  const dispatch = useDispatch();
  const favorites = useFavorites();
  const { pathname } = useLocation();
  const { seed_tracks: seedTracks } = useGenerator();
  const player = usePlayer();
  const hasTracks = usePlayerHasTracks();
  const { auth } = useUser();

  const { handleClickSeed } = useGeneratorClickHandlers();

  const previousError = useRef<string | null>('');
  const previousFavorites = usePrevious(favorites);

  const updateSavedStatus = (fn: (status: boolean) => any) => fn;

  useUpdateEffect(() => {
    if (!previousFavorites) {
      return;
    }

    if (previousFavorites.length !== favorites.length && player.offset) {
      if (player.uris[player.offset]) {
        const [, , id] = player.uris[player.offset].split(':');

        if (id) {
          updateSavedStatus(() => favorites.includes(id));
        }
      }
    }
  }, [favorites, player.offset, player.uris, previousFavorites]);

  const handlePlayerCallback = async ({ type, ...state }: CallbackState) => {
    const { errorType, isPlaying, isSaved, track } = state;
    const isTrackMismatch =
      player.track && track.uri !== getTrackUri(player.track) && track.name !== player.track.name;

    if (isPlaying !== player.isPlaying || isTrackMismatch) {
      const data: Partial<PlayerState> = { isPlaying };

      if (isTrackMismatch) {
        // eslint-disable-next-line no-console
        console.log('••• TRACK MISMATCH', player.track, track);
        data.track = track;
      }

      dispatch(playerUpdate(data));
    } else if (isPlaying && track && !player.track) {
      dispatch(playerUpdate({ track }));
    }

    if (type === TYPE.TRACK) {
      if (isSaved !== favorites.includes(track.uri)) {
        dispatch(updateTrackStatus({ id: track.id, isSaved }));
      }

      const trackStyles = await request(
        `https://scripts.gilbarbara.dev/api/getImagePlayerStyles?url=${track.image}`,
      );

      setStyles(trackStyles);
    }

    previousError.current = errorType;
  };

  const getOAuthToken: Props['getOAuthToken'] = async callback => {
    if (auth.expiresAt > now()) {
      return callback(auth.accessToken);
    }

    dispatch(refreshToken());

    return new Promise(resolve => {
      let currentValue: any;
      const unsubscribe = store.subscribe(() => {
        const { user } = store.getState();
        const previousValue = currentValue;

        currentValue = user.refreshToken.status;

        if (previousValue !== currentValue && currentValue === ASYNC_STATUS.SUCCESS) {
          callback(user.auth.accessToken);
          resolve(unsubscribe());
        }
      });
    });
  };

  const handleClickContext = () => {
    if (player.origin && (player.track || player.artist)) {
      dispatch(
        navigateTo(player.origin, player.artist ? player.artist.uri : getTrackUri(player.track)),
      );
    }
  };

  const content: Record<string, ReactNode> = {};
  let id = '';

  if (player.track) {
    id = 'uri' in player.track ? player.track.id : player.track.spotifyId;
    const inGenerator = seedTracks.some(t => t.id === id);

    content.generatorButton = (
      <ButtonUnstyled
        data-id={id}
        data-selected={inGenerator}
        data-title={player.track?.name}
        data-type="artist"
        height={32}
        justify="center"
        onClick={handleClickSeed}
        title="Add to generator"
        variant={inGenerator ? 'primary' : undefined}
        width={32}
      >
        <Icon name="bolt" size={24} title={null} />
      </ButtonUnstyled>
    );
  }

  content.contextButton = (
    <ButtonUnstyled
      disabled={pathname === player.origin}
      height={32}
      justify="center"
      onClick={handleClickContext}
      title="Return to track"
      width={32}
    >
      <Icon name="track" size={24} title={null} />
    </ButtonUnstyled>
  );

  return (
    <Wrapper isVisible={hasTracks}>
      <SpotifyPlayer
        callback={handlePlayerCallback}
        components={{
          leftButton: content.generatorButton,
          rightButton: content.contextButton,
        }}
        getOAuthToken={getOAuthToken}
        name="musicbot.io"
        offset={player.offset ?? undefined}
        persistDeviceSelection
        play={player.isPlaying}
        showSaveIcon
        styles={{
          altColor: secondaryColor,
          bgColor: '#282828',
          color: '#fff',
          loaderColor: primaryColor,
          sliderColor: primaryColor,
          sliderTrackColor: '#999',
          sliderHandleColor: '#fff',
          trackArtistColor: primaryColor,
          trackNameColor: '#fff',
          ...styles,
        }}
        token={auth.accessToken}
        updateSavedStatus={updateSavedStatus}
        uris={player.uris}
      />
    </Wrapper>
  );
}

export default Player;
