/* eslint-disable camelcase,no-underscore-dangle */
import { Musicbot } from '@gilbarbara/services-types';
import SpotifyApiJs from 'spotify-web-api-js';

import { spotify } from '~/config';

import { getTrackUri } from '~/modules/helpers';

import { SpotifyTopOptions } from '~/types';

const spotifyApi = new SpotifyApiJs();

export async function addToSavedTracks(payload: string) {
  return spotifyApi.addToMySavedTracks([payload]).then(() => payload);
}

export async function addTracksToPlaylist(
  spotifyId: string,
  playlist: Musicbot.PlaylistWithTracks,
): Promise<string> {
  return new Promise((resolve, reject) => {
    if (!spotifyId) {
      reject(new Error('Playlist is not exported to Spotify'));

      return;
    }

    const tracks: string[][] = [];

    if (playlist.tracks.length > 50) {
      for (let index = 0; index < playlist.tracks.length; index += 50) {
        tracks.push(playlist.tracks.map(d => getTrackUri(d)).slice(index, index + 50));
      }
    } else {
      tracks.push(playlist.tracks.map(d => getTrackUri(d)));
    }

    Promise.all(tracks.map(d => spotifyApi.addTracksToPlaylist(spotifyId, d))).then(() => {
      resolve(playlist.id);
    });
  });
}

export async function createPlaylist(userId: string, playlist: Musicbot.PlaylistWithTracks) {
  if (!playlist) {
    throw new Error('Playlist not found');
  }

  const { seed_artists, seed_genres, seed_tracks, ...rest } = playlist.options;
  const description: string[] = [];

  if (Array.isArray(seed_tracks) && seed_tracks.length) {
    description.push(`Tracks: ${seed_tracks.map(d => d.name).join(', ')}`);
  }

  if (Array.isArray(seed_artists) && seed_artists.length) {
    description.push(`Artists: ${seed_artists.map(d => d.name).join(', ')}`);
  }

  if (Array.isArray(seed_genres) && seed_genres.length) {
    description.push(`Genres: ${seed_genres.map(d => d.name).join(', ')}`);
  }

  if (rest) {
    const optionsKeys = Object.keys(rest) as Array<keyof typeof rest>;
    const params = optionsKeys.map(d => `${d}: ${rest[d]}`).join(', ');

    if (params.length) {
      description.push(`Parameters: ${params}`);
    }
  }

  return spotifyApi
    .createPlaylist(userId, {
      name: playlist.name,
      description: description.join(' • '),
      public: false,
    })
    .then(response => ({ id: playlist.id, body: response }));
}

export async function getAudioFeatures(payload: string) {
  return spotifyApi.getAudioFeaturesForTrack(payload).then(response => response);
}

export function getAuthorizeUrl() {
  const parameters = {
    client_id: spotify.clientId,
    response_type: 'code',
    redirect_uri: spotify.redirectUri,
    scope: spotify.scopes.join(' '),
    state: 'auth',
  };

  return `${spotify.accountApiUrl}/authorize?${new URLSearchParams(parameters)}`;
}

export async function getLibrary(payload = { limit: 50, offset: 0 }) {
  return spotifyApi.getMySavedTracks(payload).then(response => response);
}

export async function getRecommendations(seed: SpotifyApi.RecommendationsOptionsObject) {
  return spotifyApi.getRecommendations(seed).then(response => response);
}

export async function getTopArtists(options: SpotifyTopOptions) {
  return spotifyApi.getMyTopArtists(options).then(response => response);
}

export async function getTopTracks(options: SpotifyTopOptions) {
  return spotifyApi.getMyTopTracks(options).then(response => response);
}

export async function getTracks(payload: string[]) {
  return spotifyApi.getTracks(payload).then(response => response);
}

export async function getTracksStatus(payload: string | string[]) {
  return spotifyApi
    .containsMySavedTracks(Array.isArray(payload) ? payload : [payload])
    .then(response => response);
}

export async function getUserData() {
  return spotifyApi.getMe().then(response => response);
}

export function parseSpotifyTracks(
  tracks: Array<SpotifyApi.TrackObjectFull | SpotifyApi.RecommendationTrackObject>,
): Musicbot.TrackCreate[] {
  return tracks.map(d => parseTrack(d));
}

export function parseTrack(
  track: SpotifyApi.TrackObjectFull | SpotifyApi.RecommendationTrackObject,
): Musicbot.TrackCreate {
  return {
    artists: track.artists.map(d => ({ name: d.name, url: d.external_urls.spotify })),
    image: track.album.images[0].url || '',
    name: track.name,
    spotifyId: track.id,
    title: `${track.artists[0].name} - ${track.name}`,
    url: track.external_urls.spotify,
  };
}

export async function removeFromMySavedTracks(payload: string) {
  return spotifyApi.removeFromMySavedTracks([payload]).then(() => payload);
}

export async function setTokens(auth: Musicbot.UserCredentials) {
  spotifyApi.setAccessToken(auth.accessToken);
}

export default spotifyApi;
