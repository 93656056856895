import { Types } from '@gilbarbara/components';
import { IdName } from '@gilbarbara/types';
import { createSlice, original, PayloadAction } from '@reduxjs/toolkit';
import { produce } from 'immer';

import { storageVersion } from '~/config';

import { actionBody, rehydrateAction } from '~/modules/helpers';

import { AppState, Generator, GeneratorSeedTypes } from '~/types';

import user from './user';

export const appState: AppState = {
  breakpoint: null,
  generator: {
    isPristine: true,
    limit: 100,
    seed_artists: [],
    seed_genres: [],
    seed_tracks: [],
  },
  href: '',
  storageVersion,
  uri: null,
};

function cleanupState(state: AppState) {
  return produce(state, draft => {
    draft.breakpoint = null;
    draft.href = '';
    draft.uri = null;
  });
}

export default createSlice({
  name: 'app',
  initialState: appState,
  extraReducers: builder => {
    builder.addCase(rehydrateAction, (draft, { payload }) => {
      return cleanupState({ ...original(draft), ...payload?.app } as AppState);
    });

    builder.addCase(user.actions.logout, () => {
      return appState;
    });
  },
  reducers: {
    addToGenerator: {
      reducer: (draft, { payload }: PayloadAction<{ data: IdName; key: GeneratorSeedTypes }>) => {
        draft.generator.isPristine = false;

        draft.generator[payload.key] = [payload.data, ...draft.generator[payload.key]].slice(0, 5);
      },
      prepare: (key: GeneratorSeedTypes, data: IdName) => actionBody({ data, key }),
    },
    navigateTo: {
      reducer: (draft, { payload }: PayloadAction<{ href: string; uri?: string }>) => {
        draft.href = payload.href;

        if (payload.uri) {
          draft.uri = payload.uri;
        }
      },
      prepare: (href: string, uri?: string) => actionBody({ href, uri }),
    },
    removeFromGenerator: {
      reducer: (draft, { payload }: PayloadAction<{ data: IdName; key: GeneratorSeedTypes }>) => {
        draft.generator.isPristine = false;
        draft.generator[payload.key] = draft.generator[payload.key].filter(
          item => item.id !== payload.data.id,
        );
      },
      prepare: (key: GeneratorSeedTypes, data: IdName) => actionBody({ data, key }),
    },
    resetHref: draft => {
      draft.href = '';
    },
    resetGenerator: draft => {
      draft.generator = appState.generator;
    },
    resetUri: draft => {
      draft.uri = null;
    },
    saveGeneratorOptions: (draft, { payload }: PayloadAction<Partial<Generator>>) => {
      draft.generator = {
        ...draft.generator,
        ...payload,
        isPristine: false,
      };
    },
    setBreakpoint: (draft, { payload }: PayloadAction<Types.Breakpoints>) => {
      draft.breakpoint = payload;
    },
  },
});
