import { mergeTheme, Types } from '@gilbarbara/components';

export const footerHeight = 60;
export const headerHeight = {
  sm: 80,
  md: 70,
};
export const containerWidth = 1440;
export const playerHeight = '80px';
export const playerColor = '#282828';

export const primaryColor = '#ff6e00'; // #ff0044 | #ffa047 | #00c200 | #fbbc05
export const secondaryColor = '#0091ff'; // #00996e | #7a3900 | #005c00 | #665200
export const spotifyColor = '#1db954';

export const theme = mergeTheme({
  colors: {
    primary: primaryColor,
    secondary: secondaryColor,
  },
  button: {
    borderRadius: {
      sm: '24px',
      md: '24px',
      lg: '24px',
    },
    padding: {
      sm: ['6px', '18px'],
      md: ['8px', '22px'],
      lg: ['10px', '26px'],
    },
  },
});

export const boxColor = theme.grayDarker;
export const boxRadius = theme.spacing.sm;

export const breakpoints = Object.entries(theme.breakpoints).reduce((acc, [key, value]) => {
  acc[key as Types.Breakpoints] = parseInt(value, 10);

  return acc;
}, {} as Record<Types.Breakpoints, number>);

export const easing = 'cubic-bezier(0.35, 0.01, 0.77, 0.34);';
