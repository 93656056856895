import styled from '@emotion/styled';
import { Box, Button, Icon } from '@gilbarbara/components';

import { event } from '~/modules/analytics';
import { getAuthorizeUrl } from '~/modules/spotify';

import { useUser } from '~/services';

const Anchor = styled(Button)`
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`;

function Connect() {
  const { isAuthenticated } = useUser();
  const authUrl = getAuthorizeUrl();

  const handleClick = () => {
    event('connect', 'click');
  };

  if (isAuthenticated) {
    return null;
  }

  return (
    <Box flexBox justify="center">
      <Anchor as="a" href={authUrl} onClick={handleClick} size="lg">
        <Icon mr="xxs" name="spotify" size={28} />
        <span>Connect</span>
      </Anchor>
    </Box>
  );
}

export default Connect;
