import { ASYNC_STATUS, now, pluralize } from '@gilbarbara/helpers';
import { Musicbot } from '@gilbarbara/services-types';
import { createSlice, original, PayloadAction } from '@reduxjs/toolkit';
import produce from 'immer';

import { actionBody, rehydrateAction } from '~/modules/helpers';

import {
  GeneratorRequest,
  GetRecommendationsResponse,
  PlaylistsState,
  RecommendationsOptions,
} from '~/types';

import user from './user';

export const playlistsState: PlaylistsState = {
  exportPlaylist: {
    message: '',
    id: undefined,
    status: ASYNC_STATUS.IDLE,
  },
  generatePlaylist: {
    name: '',
    options: {},
    status: ASYNC_STATUS.IDLE,
    tracks: [],
  },
  get: {
    data: null,
    message: '',
    status: ASYNC_STATUS.IDLE,
    updatedAt: 0,
  },
  getAll: {
    data: [],
    message: '',
    status: ASYNC_STATUS.IDLE,
    updatedAt: 0,
  },
  getRecommendations: {
    data: null,
    message: '',
    status: ASYNC_STATUS.IDLE,
  },
  removePlaylist: {
    message: '',
    status: ASYNC_STATUS.IDLE,
  },
  removeTrack: {
    message: '',
    status: ASYNC_STATUS.IDLE,
  },
  savePlaylist: {
    message: '',
    status: ASYNC_STATUS.IDLE,
  },
  updatePlaylist: {
    message: '',
    status: ASYNC_STATUS.IDLE,
  },
};

function cleanupState(state: PlaylistsState) {
  return produce(state, draft => {
    draft.exportPlaylist.status = ASYNC_STATUS.IDLE;
    draft.generatePlaylist.status = ASYNC_STATUS.IDLE;
    draft.get.status = ASYNC_STATUS.IDLE;
    draft.getAll.status = ASYNC_STATUS.IDLE;
    draft.getRecommendations.status = ASYNC_STATUS.IDLE;
    draft.removePlaylist.status = ASYNC_STATUS.IDLE;
    draft.removeTrack.status = ASYNC_STATUS.IDLE;
    draft.savePlaylist.status = ASYNC_STATUS.IDLE;
    draft.updatePlaylist.status = ASYNC_STATUS.IDLE;
  });
}

export default createSlice({
  name: 'playlists',
  initialState: playlistsState,
  extraReducers: builder => {
    builder.addCase(rehydrateAction, (draft, { payload }) => {
      return cleanupState({ ...original(draft), ...payload?.playlists } as PlaylistsState);
    });

    builder.addCase(user.actions.logout, () => {
      return playlistsState;
    });
  },
  reducers: {
    exportPlaylist: {
      reducer: draft => {
        draft.exportPlaylist.message = '';
        draft.exportPlaylist.status = ASYNC_STATUS.PENDING;
      },
      prepare: (id: string) => actionBody(id),
    },
    exportPlaylistSuccess: (
      draft,
      {
        payload,
      }: PayloadAction<{
        body: SpotifyApi.CreatePlaylistResponse;
        id: string;
      }>,
    ) => {
      draft.getAll.data = draft.getAll.data.map(d =>
        d.id === payload.id
          ? {
              ...d,
              exported: true,
              spotify_id: payload.body.id,
              url: payload.body.external_urls.spotify,
            }
          : d,
      );
      draft.exportPlaylist.status = ASYNC_STATUS.SUCCESS;
    },
    exportPlaylistFailure: (draft, { payload }: PayloadAction<string>) => {
      draft.exportPlaylist.status = ASYNC_STATUS.ERROR;
      draft.exportPlaylist.message = payload;
    },
    generatePlaylist: {
      reducer: (draft, { payload }: PayloadAction<GeneratorRequest>) => {
        const { name, options } = payload;

        Object.assign(draft.generatePlaylist, {
          name,
          options,
          status: ASYNC_STATUS.PENDING,
        });
      },
      prepare: (seeds: GeneratorRequest['seeds'], meta: GeneratorRequest['options']) => {
        if (!seeds) {
          throw new Error('No seeds');
        }

        const seedsNames = [...meta.seed_tracks, ...meta.seed_artists, ...meta.seed_genres].map(
          d => d.name,
        );

        return actionBody({
          name: `Based on ${seedsNames[0]}${
            seedsNames.length > 1
              ? ` — and ${seedsNames.length - 1} other ${pluralize(seedsNames.length, 'seed')}`
              : ''
          }`,
          options: meta,
          seeds,
        });
      },
    },
    generatePlaylistSuccess: draft => {
      Object.assign(draft.generatePlaylist, playlistsState.generatePlaylist);
    },
    getPlaylist: {
      reducer: draft => {
        draft.get.message = '';
        draft.get.status = ASYNC_STATUS.PENDING;
      },
      prepare: (id: string) => actionBody(id),
    },
    getPlaylistSuccess: {
      reducer: (
        draft,
        { payload }: PayloadAction<{ playlist: Musicbot.PlaylistWithTracks; updatedAt: number }>,
      ) => {
        draft.get.data = payload.playlist;
        draft.get.status = ASYNC_STATUS.SUCCESS;
        draft.get.updatedAt = payload.updatedAt;
      },
      prepare: (playlist: Musicbot.PlaylistWithTracks, updatedAt: number = now()) =>
        actionBody({ playlist, updatedAt }),
    },
    getPlaylistFailure: (draft, { payload }: PayloadAction<string>) => {
      draft.get.data = null;
      draft.get.status = ASYNC_STATUS.ERROR;
      draft.get.message = payload;
    },
    getPlaylists: draft => {
      draft.getAll.message = '';
      draft.getAll.status = ASYNC_STATUS.PENDING;
    },
    getPlaylistsSuccess: {
      reducer: (
        draft,
        { payload }: PayloadAction<{ playlists: Musicbot.Playlist[]; updatedAt: number }>,
      ) => {
        draft.getAll.data = payload.playlists;
        draft.getAll.status = ASYNC_STATUS.SUCCESS;
        draft.getAll.updatedAt = payload.updatedAt;
      },
      prepare: (playlists: Musicbot.Playlist[], updatedAt: number = now()) =>
        actionBody({ playlists, updatedAt }),
    },
    getPlaylistsFailure: (draft, { payload }: PayloadAction<string>) => {
      draft.getAll.data = [];
      draft.getAll.status = ASYNC_STATUS.ERROR;
      draft.getAll.message = payload;
    },
    getRecommendations: {
      reducer: draft => {
        draft.getRecommendations.message = '';
        draft.getRecommendations.status = ASYNC_STATUS.PENDING;
      },
      prepare: (seeds: RecommendationsOptions) => actionBody(seeds),
    },
    getRecommendationsSuccess: (draft, { payload }: PayloadAction<GetRecommendationsResponse>) => {
      draft.getRecommendations.data = payload;
      draft.getRecommendations.status = ASYNC_STATUS.SUCCESS;
      draft.generatePlaylist.tracks = payload.tracks;
    },
    getRecommendationsFailure: (draft, { payload }: PayloadAction<string>) => {
      draft.getRecommendations.status = ASYNC_STATUS.ERROR;
      draft.getRecommendations.message = payload;
    },
    removeFromPlaylist: {
      reducer: draft => {
        draft.removeTrack.message = '';
        draft.removeTrack.status = ASYNC_STATUS.PENDING;
      },
      prepare: (playlistId: string, spotifyId: string) => actionBody({ playlistId, spotifyId }),
    },
    removeFromPlaylistSuccess: {
      reducer: (draft, { payload }: PayloadAction<{ playlistId: string; spotifyId: string }>) => {
        const { playlistId, spotifyId } = payload;
        const playlist = draft.get.data;

        if (playlist?.id === playlistId) {
          draft.get.data = {
            ...playlist,
            tracks: playlist.tracks.filter(t => t.spotifyId !== spotifyId),
          };
        }

        draft.removeTrack.status = ASYNC_STATUS.SUCCESS;
      },
      prepare: (playlistId: string, spotifyId: string) => actionBody({ playlistId, spotifyId }),
    },
    removeFromPlaylistFailure: (draft, { payload }: PayloadAction<string>) => {
      draft.removeTrack.status = ASYNC_STATUS.ERROR;
      draft.removeTrack.message = payload;
    },
    removePlaylist: {
      reducer: draft => {
        draft.removePlaylist.message = '';
        draft.removePlaylist.status = ASYNC_STATUS.PENDING;
      },
      prepare: (id: string) => actionBody(id),
    },
    removePlaylistSuccess: (draft, { payload }: PayloadAction<string>) => {
      draft.getAll.data = draft.getAll.data.filter(d => d.id !== payload);
      draft.removePlaylist.status = ASYNC_STATUS.SUCCESS;
    },
    removePlaylistFailure: (draft, { payload }: PayloadAction<string>) => {
      draft.removePlaylist.status = ASYNC_STATUS.ERROR;
      draft.removePlaylist.message = payload;
    },
    resetPlaylistsCache: draft => {
      draft.getAll.updatedAt = 0;
    },
    savePlaylist: {
      reducer: draft => {
        draft.savePlaylist.message = '';
        draft.savePlaylist.status = ASYNC_STATUS.PENDING;
      },
      prepare: (playlist: Musicbot.PlaylistCreate) => actionBody(playlist),
    },
    savePlaylistSuccess: (draft, { payload }: PayloadAction<Musicbot.Playlist>) => {
      draft.getAll.data = draft.getAll.data.map(d =>
        d.id === payload.id ? { ...d, ...payload } : d,
      );
      draft.savePlaylist.status = ASYNC_STATUS.SUCCESS;
    },
    savePlaylistFailure: (draft, { payload }: PayloadAction<string>) => {
      draft.savePlaylist.status = ASYNC_STATUS.ERROR;
      draft.savePlaylist.message = payload;
    },
    updatePlaylist: {
      reducer: draft => {
        draft.updatePlaylist.message = '';
        draft.updatePlaylist.status = ASYNC_STATUS.PENDING;
      },
      prepare: (id: string, data: Musicbot.PlaylistUpdate) => actionBody({ id, data }),
    },
    updatePlaylistSuccess: (draft, { payload }: PayloadAction<Partial<Musicbot.Playlist>>) => {
      if (draft.get.data) {
        Object.assign(draft.get.data, payload);
      }

      draft.getAll.data = draft.getAll.data.map(d =>
        d.id === payload.id ? { ...d, ...payload } : d,
      );
      draft.updatePlaylist.status = ASYNC_STATUS.SUCCESS;
    },
    updatePlaylistFailure: (draft, { payload }: PayloadAction<string>) => {
      draft.updatePlaylist.status = ASYNC_STATUS.ERROR;
      draft.updatePlaylist.message = payload;
    },
  },
});
