import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as Spotify from '~/modules/spotify';

import {
  getAudioFeatures,
  getAudioFeaturesFailure,
  getAudioFeaturesSuccess,
  getLibrary,
  getLibraryFailure,
  getLibrarySuccess,
  getTopArtists,
  getTopArtistsFailure,
  getTopArtistsSuccess,
  getTopTracks,
  getTopTracksFailure,
  getTopTracksSuccess,
  getTracksStatus,
  getTracksStatusFailure,
  getTracksStatusSuccess,
  removeTrack,
  removeTrackFailure,
  removeTrackSuccess,
  saveTrack,
  saveTrackFailure,
  saveTrackSuccess,
} from '~/services';

function* getAudioFeaturesSaga({ payload }: ReturnType<typeof getAudioFeatures>) {
  try {
    const data: Awaited<ReturnType<typeof Spotify.getAudioFeatures>> = yield call(
      Spotify.getAudioFeatures,
      payload,
    );

    yield put(getAudioFeaturesSuccess(data));
  } catch (error: any) {
    yield put(getAudioFeaturesFailure(payload, error.message));
  }
}

function* getLibrarySaga({ payload }: ReturnType<typeof getLibrary>) {
  try {
    const data: Awaited<ReturnType<typeof Spotify.getLibrary>> = yield call(
      Spotify.getLibrary,
      payload,
    );

    yield put(getLibrarySuccess(data));
  } catch (error: any) {
    yield put(getLibraryFailure(error.message));
  }
}

function* getTopArtistsSaga({ payload }: ReturnType<typeof getTopArtists>) {
  try {
    const artists: Awaited<ReturnType<typeof Spotify.getTopArtists>> = yield call(
      Spotify.getTopArtists,
      payload,
    );

    yield put(getTopArtistsSuccess(artists));
  } catch (error: any) {
    yield put(getTopArtistsFailure(error.message));
  }
}

function* getTopTracksSaga({ payload }: ReturnType<typeof getTopTracks>) {
  try {
    const tracks: SpotifyApi.UsersTopTracksResponse = yield call(Spotify.getTopTracks, payload);

    yield put(getTopTracksSuccess(tracks));
  } catch (error: any) {
    yield put(getTopTracksFailure(error.message));
  }
}

function* getTracksStatusSaga({ payload }: ReturnType<typeof getTracksStatus>) {
  try {
    const max = 50;
    const data: string[] = [];

    if (payload.length > max) {
      const batches = new Array(Math.ceil(payload.length / max));

      for (let index = 0; index < batches.length; index++) {
        const start = index * max;
        const tracks = payload.slice(start, 50 * (index + 1));

        const batch: boolean[] = yield call(Spotify.getTracksStatus, tracks);

        tracks.forEach((track, trackIndex) => {
          if (batch[trackIndex]) {
            data.push(track);
          }
        });
      }
    } else {
      const batch: boolean[] = yield call(Spotify.getTracksStatus, payload);

      payload.forEach((d: string, index: number) => {
        if (batch[index]) {
          data.push(d);
        }
      });
    }

    yield put(getTracksStatusSuccess(data));
  } catch (error: any) {
    yield put(getTracksStatusFailure(error.message));
  }
}

function* removeFromFavorites({ payload }: ReturnType<typeof removeTrack>) {
  try {
    const id: string = yield call(Spotify.removeFromMySavedTracks, payload);

    yield put(removeTrackSuccess(id));
  } catch (error: any) {
    yield put(removeTrackFailure(error.message));
  }
}

function* saveToFavorites({ payload }: ReturnType<typeof saveTrack>) {
  try {
    const id: string = yield call(Spotify.addToSavedTracks, payload);

    yield put(saveTrackSuccess(id));
  } catch (error: any) {
    yield put(saveTrackFailure(error.message));
  }
}

export default function* root() {
  yield all([
    takeLatest(getAudioFeatures.type, getAudioFeaturesSaga),
    takeLatest(getLibrary.type, getLibrarySaga),
    takeLatest(getTopArtists.type, getTopArtistsSaga),
    takeLatest(getTopTracks.type, getTopTracksSaga),
    takeLatest(getTracksStatus.type, getTracksStatusSaga),
    takeLatest(removeTrack.type, removeFromFavorites),
    takeLatest(saveTrack.type, saveToFavorites),
  ]);
}
