import { CSSProperties, HTMLAttributes, ReactNode } from 'react';
import { Container } from '@gilbarbara/components';

import { minScreenSize } from '~/modules/dom';
import { containerWidth, footerHeight, headerHeight } from '~/modules/theme';

import { useUser } from '~/services';

interface Props extends HTMLAttributes<HTMLDivElement> {
  centered?: boolean;
  children: ReactNode;
  fullWidth?: boolean;
  name: string;
  skipSpacing?: boolean;
}

export default function Page(props: Props) {
  const { centered, children, fullWidth, name, skipSpacing, ...rest } = props;
  const { isAuthenticated } = useUser();

  const offset = (minScreenSize('lg') ? headerHeight.md : headerHeight.sm) + footerHeight;
  const styles: CSSProperties = {};

  if (skipSpacing) {
    styles.padding = 0;
  }

  return (
    <Container
      align={centered ? 'center' : 'stretch'}
      data-component-name={name}
      fullScreen
      fullScreenOffset={isAuthenticated ? offset : 0}
      justify={centered ? 'center' : 'start'}
      maxWidth={fullWidth ? '100%' : containerWidth}
      padding={0}
      responsive={false}
      style={styles}
      textAlign={centered ? 'center' : 'left'}
      verticalPadding
      {...rest}
    >
      {children}
    </Container>
  );
}
