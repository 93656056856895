import { configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistCombineReducers,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';

import alertsSlice from '~/services/slices/alerts';
import appSlice from '~/services/slices/app';
import playerSlice from '~/services/slices/player';
import playlistsSlice from '~/services/slices/playlists';
import spotifySlice from '~/services/slices/spotify';
import userSlice from '~/services/slices/user';
import rootSaga from '~/sagas';

import { RootState } from '~/types';

import dynamicMiddlewares from './dynamic-middlewares';
import middlewares, { sagaMiddleware } from './middlewares';

const getDefaultMiddlewareOptions = {
  serializableCheck: {
    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
  },
  thunk: false,
};

const rootReducer = persistCombineReducers<RootState>(
  {
    key: 'musicbot',
    blacklist: ['alerts', 'player'],
    stateReconciler: autoMergeLevel2,
    storage,
    timeout: 0,
    version: 1,
  },
  {
    [alertsSlice.name]: alertsSlice.reducer,
    [appSlice.name]: appSlice.reducer,
    [playerSlice.name]: playerSlice.reducer,
    [playlistsSlice.name]: playlistsSlice.reducer,
    [spotifySlice.name]: spotifySlice.reducer,
    [userSlice.name]: userSlice.reducer,
  },
);

/* istanbul ignore next */
export const configStore = (preloadedState: any = {}) => {
  const storeEnhanced = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => [
      ...getDefaultMiddleware(getDefaultMiddlewareOptions),
      ...middlewares,
      dynamicMiddlewares,
    ],
    preloadedState,
  });

  sagaMiddleware.run(rootSaga);

  return {
    persistor: persistStore(storeEnhanced),
    store: storeEnhanced,
  };
};

export const { persistor, store } = configStore();

window.store = store;
