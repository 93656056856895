import { ASYNC_STATUS, now } from '@gilbarbara/helpers';
import { AnyAction, Middleware } from 'redux';

import { login, logout, refreshToken, refreshTokenFailure, refreshTokenSuccess } from '~/services';

import { RootState } from '~/types';

let pendingActions: AnyAction[] = [];

export default function createAuthMiddleware(): Middleware<any, RootState> {
  return store => next => action => {
    const { type = '' } = action;
    const { user } = store.getState();

    const isAuthenticationRequest = [login.type, refreshToken.type, logout.type].includes(type);
    const isRefreshTokenFailure = type === refreshTokenFailure.type;
    const isRefreshTokenSuccess = type === refreshTokenSuccess.type;

    if (type === refreshTokenSuccess.type) {
      next(action);
      // console.log('auth-middleware:refreshTokenSuccess', pendingActions);

      pendingActions.forEach(d => {
        store.dispatch(d);
      });

      pendingActions = [];

      return;
    }

    if (!isAuthenticationRequest && user.isAuthenticated && user.auth.expiresAt < now()) {
      if (!['Success', 'Failure'].some(d => type.endsWith(d))) {
        // Include the request action in the pendingActions
        pendingActions.push(action);
        // console.log('auth-middleware:pendingActions.push', action);
      }

      if (!isRefreshTokenSuccess && user.refreshToken.status !== ASYNC_STATUS.PENDING) {
        // console.log('auth-middleware:refreshToken');
        store.dispatch(refreshToken());
      }

      return;
    }

    next(action);

    /* istanbul ignore else */
    if (isRefreshTokenFailure) {
      store.dispatch(logout());
    }
  };
}
