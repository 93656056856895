import SVG from 'react-inlinesvg';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { BoxInline, BoxProps, px } from '@gilbarbara/components';

import { appName } from '~/config';

interface Props extends BoxProps {
  color?: BoxProps['colorVariant'];
  height?: number;
  /** @default logo */
  type?: 'logo' | 'logo-vertical';
  width?: number;
}

const logoVerticalRatio = 512 / 335;
const logoHorizontalRatio = 512 / 58;

const Wrapper = styled(BoxInline)<Props>(props => {
  const { height, type = 'logo', width } = props;
  const ratio = type === 'logo' ? logoHorizontalRatio : logoVerticalRatio;
  let innerHeight = height;
  let innerWidth = width;

  if (!height && width) {
    innerHeight = width / ratio;
  }

  if (height && !width) {
    innerWidth = height * ratio;
  }

  return css`
    svg {
      height: ${px(innerHeight)};
      width: ${px(innerWidth)};
    }
  `;
});

function Logo(props: Props) {
  const { type = 'logo' } = props;

  return (
    <Wrapper {...props} data-component-name="Logo">
      <SVG src={`/media/brand/${type}.svg`} title={appName} />
    </Wrapper>
  );
}

Logo.defaultProps = {
  file: 'logo',
};

export default Logo;
