import { lazy, Suspense, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Loader } from '@gilbarbara/components';
import { useSingleton, useWindowSize } from '@gilbarbara/hooks';

import { appName } from '~/config';

import { getBreakpoint } from '~/modules/dom';
import { setTokens } from '~/modules/spotify';

import { setBreakpoint, usePlayerHasTracks, useUser } from '~/services';

import App from '~/components/App';
import ErrorHandler from '~/components/ErrorHandler';
import Footer from '~/components/Footer';
import Header from '~/components/Header';
import Main from '~/components/Main';
import Navigate from '~/components/Navigate';
import PrivateRoute from '~/components/PrivateRoute';
import PublicRoute from '~/components/PublicRoute';
import ScrollRestoration from '~/components/ScrollRestoration';
import Player from '~/containers/Player';
import SystemAlerts from '~/containers/SystemAlerts';
import Callback from '~/routes/Callback';
import NotFound from '~/routes/NotFound';

import { UserData, UserState } from '~/types';

const About = lazy(() => import(/* webpackChunkName: "about" */ './routes/About'));
const Home = lazy(() => import(/* webpackChunkName: "home" */ './routes/Home'));
const Generator = lazy(() => import(/* webpackChunkName: "generator" */ './routes/Generator'));
const Library = lazy(() => import(/* webpackChunkName: "library" */ './routes/Library'));
const LibraryLikedSongs = lazy(
  () => import(/* webpackChunkName: "likedSongs" */ './routes/Library/LikedSongs'),
);
const LibraryTopArtists = lazy(
  () => import(/* webpackChunkName: "topArtists" */ './routes/Library/TopArtists'),
);
const LibraryTopTracks = lazy(
  () => import(/* webpackChunkName: "topTracks" */ './routes/Library/TopTracks'),
);
const Playlist = lazy(() => import(/* webpackChunkName: "playlist" */ './routes/Playlist'));
const Playlists = lazy(() => import(/* webpackChunkName: "playlists" */ './routes/Playlists'));
const Privacy = lazy(() => import(/* webpackChunkName: "privacy" */ './routes/Privacy'));

function Root() {
  const dispatch = useDispatch();
  const hasTracks = usePlayerHasTracks();
  const user = useUser();
  const { isAuthenticated } = user;

  useSingleton(() => {
    setTokens(user.auth);
  });

  const { width } = useWindowSize();

  useEffect(() => {
    dispatch(setBreakpoint(getBreakpoint(width)));
  }, [dispatch, width]);

  const suspenseFallback = (
    <div>
      <Loader block />
    </div>
  );

  return (
    <BrowserRouter>
      <App isAuthenticated={isAuthenticated} withPlayer={hasTracks}>
        <Helmet
          defaultTitle={appName}
          defer={false}
          encodeSpecialCharacters
          htmlAttributes={{ lang: 'en-us' }}
          titleAttributes={{ itemprop: 'name', lang: 'en-us' }}
          titleTemplate={`%s | ${appName}`}
        >
          <link href="https://fonts.googleapis.com" rel="preconnect" />
          <link crossOrigin="anonymous" href="https://fonts.gstatic.com" rel="preconnect" />
          <link
            href="https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,400;0,700;1,400;1,700&display=swap"
            rel="stylesheet"
          />
        </Helmet>
        <ScrollRestoration />
        <Navigate />
        <ErrorBoundary FallbackComponent={ErrorHandler}>
          {isAuthenticated && <Header user={user as UserState<UserData>} />}
          <Main>
            <Routes>
              <Route
                element={
                  <Suspense fallback={suspenseFallback}>
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <Generator />
                    </PrivateRoute>
                  </Suspense>
                }
                path="/generator"
              />
              <Route
                element={
                  <Suspense fallback={suspenseFallback}>
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <Library />
                    </PrivateRoute>
                  </Suspense>
                }
                path="/library"
              >
                <Route
                  element={
                    <Suspense fallback={suspenseFallback}>
                      <LibraryLikedSongs />
                    </Suspense>
                  }
                  index
                />
                <Route
                  element={
                    <Suspense fallback={suspenseFallback}>
                      <LibraryTopArtists />
                    </Suspense>
                  }
                  path="topArtists"
                />
                <Route
                  element={
                    <Suspense fallback={suspenseFallback}>
                      <LibraryTopTracks />
                    </Suspense>
                  }
                  path="topTracks"
                />
              </Route>
              <Route
                element={
                  <Suspense fallback={suspenseFallback}>
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <Playlists />
                    </PrivateRoute>
                  </Suspense>
                }
                path="/playlists"
              />
              <Route
                element={
                  <Suspense fallback={suspenseFallback}>
                    <PrivateRoute isAuthenticated={isAuthenticated}>
                      <Playlist />
                    </PrivateRoute>
                  </Suspense>
                }
                path="/playlists/:id"
              />
              <Route
                element={
                  <Suspense fallback={suspenseFallback}>
                    <About />
                  </Suspense>
                }
                path="/about"
              />
              <Route
                element={
                  <Suspense fallback={suspenseFallback}>
                    <Privacy />
                  </Suspense>
                }
                path="/privacy"
              />
              <Route
                element={
                  <Suspense fallback={suspenseFallback}>
                    <PublicRoute isAuthenticated={isAuthenticated}>
                      <Callback />
                    </PublicRoute>
                  </Suspense>
                }
                path="/callback"
              />
              <Route
                element={
                  <Suspense fallback={suspenseFallback}>
                    <PublicRoute isAuthenticated={isAuthenticated}>
                      <Home />
                    </PublicRoute>
                  </Suspense>
                }
                path="/"
              />
              <Route
                element={
                  <Suspense fallback={suspenseFallback}>
                    <NotFound />
                  </Suspense>
                }
                path="*"
              />
            </Routes>
          </Main>
          {isAuthenticated && <Footer />}
        </ErrorBoundary>
        {isAuthenticated && <Player />}
        <SystemAlerts />
      </App>
    </BrowserRouter>
  );
}

export default Root;
