import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PlayerState } from '~/types';

import user from './user';

export const playerState: PlayerState = {
  artist: null,
  isPlaying: false,
  offset: null,
  origin: null,
  track: null,
  uris: [],
};

export default createSlice({
  name: 'player',
  initialState: playerState,
  extraReducers: builder => {
    builder.addCase(user.actions.logout, () => {
      return playerState;
    });
  },
  reducers: {
    playerUpdate: (draft, { payload }: PayloadAction<Partial<PlayerState>>) => {
      Object.assign(draft, payload);
    },
    playerReset: () => playerState,
  },
});
