import { ASYNC_STATUS, now, omit } from '@gilbarbara/helpers';
import { createSlice, original, PayloadAction } from '@reduxjs/toolkit';
import produce from 'immer';

import { actionBody, rehydrateAction } from '~/modules/helpers';

import { SpotifyGetLibraryInput, SpotifyState, SpotifyTopOptions } from '~/types';

import user from './user';

export const spotifyState: SpotifyState = {
  audioFeatures: [],
  favorites: {
    data: [],
    message: '',
    status: ASYNC_STATUS.IDLE,
  },
  library: {
    data: [],
    message: '',
    status: ASYNC_STATUS.IDLE,
    total: 0,
    updatedAt: 0,
  },
  removeTracks: [],
  saveTracks: [],
  topArtists: {
    data: [],
    message: '',
    status: ASYNC_STATUS.IDLE,
    timeRange: 'medium_term',
    total: 0,
    updatedAt: 0,
  },
  topTracks: {
    data: [],
    message: '',
    status: ASYNC_STATUS.IDLE,
    timeRange: 'medium_term',
    total: 0,
    updatedAt: 0,
  },
};

function mergeFavorites(favorites: string[], data: string[], remove?: boolean): string[] {
  let merged = [...favorites];

  data.forEach((id: string) => {
    const exists = merged.includes(id);

    if (remove) {
      merged = merged.filter((d: string): boolean => d !== id);
    } else if (!exists) {
      merged.push(id);
    }
  });

  return merged;
}

function cleanupState(state: SpotifyState) {
  return produce(state, draft => {
    draft.favorites.status = ASYNC_STATUS.IDLE;
    draft.library.status = ASYNC_STATUS.IDLE;
    draft.topArtists.status = ASYNC_STATUS.IDLE;
    draft.topTracks.status = ASYNC_STATUS.IDLE;
    draft.removeTracks = [];
    draft.saveTracks = [];
  });
}

export default createSlice({
  name: 'spotify',
  initialState: spotifyState,
  extraReducers: builder => {
    builder.addCase(rehydrateAction, (draft, { payload }) => {
      return cleanupState({ ...original(draft), ...payload?.spotify } as SpotifyState);
    });

    builder.addCase(user.actions.logout, () => {
      return spotifyState;
    });
  },
  reducers: {
    getAudioFeatures: {
      reducer: (draft, { payload }: PayloadAction<string>) => {
        draft.audioFeatures.push({
          message: '',
          id: payload,
          status: ASYNC_STATUS.PENDING,
        });
      },
      prepare: (id: string) => actionBody(id),
    },
    getAudioFeaturesSuccess: {
      reducer: (draft, { payload }: PayloadAction<SpotifyApi.AudioFeaturesResponse>) => {
        const attributes = omit(payload, 'analysis_url', 'track_href', 'type', 'uri');

        draft.audioFeatures = draft.audioFeatures.map(d =>
          d.id !== payload.id
            ? d
            : {
                ...d,
                ...attributes,
                status: ASYNC_STATUS.SUCCESS,
              },
        );
      },
      prepare: (audioFeatures: SpotifyApi.AudioFeaturesResponse) => actionBody(audioFeatures),
    },
    getAudioFeaturesFailure: {
      reducer: (draft, { payload }: PayloadAction<{ id: string; message: string }>) => {
        draft.audioFeatures = draft.audioFeatures.map(d =>
          d.id !== payload.id
            ? d
            : {
                ...d,
                message: payload.message,
                status: ASYNC_STATUS.ERROR,
              },
        );
      },
      prepare: (id: string, message: string) => actionBody({ id, message }),
    },
    getLibrary: {
      reducer: (draft, { payload }: PayloadAction<SpotifyGetLibraryInput>) => {
        draft.library.data = payload.initial ? [] : draft.library.data;
        draft.library.message = '';
        draft.library.status = ASYNC_STATUS.PENDING;
      },
      prepare: (options?: SpotifyGetLibraryInput) => {
        const { initial = false, limit = 50, offset = 0 } = options || {};

        return actionBody({ initial, limit, offset });
      },
    },
    getLibrarySuccess: {
      reducer: (
        draft,
        {
          meta,
          payload,
        }: PayloadAction<SpotifyApi.UsersSavedTracksResponse, string, { updatedAt: number }>,
      ) => {
        draft.library.data = [...draft.library.data, ...payload.items];
        draft.library.status = ASYNC_STATUS.SUCCESS;
        draft.library.total = payload.total;
        draft.library.updatedAt = meta.updatedAt;

        draft.favorites.data = mergeFavorites(
          draft.favorites.data,
          payload.items.map(d => d.track.id),
        );
      },
      prepare: (data: SpotifyApi.UsersSavedTracksResponse) =>
        actionBody(data, { updatedAt: now() }),
    },
    getLibraryFailure: (draft, { payload }: PayloadAction<string>) => {
      draft.library.message = payload;
      draft.library.status = ASYNC_STATUS.ERROR;
    },
    getTopArtists: {
      reducer: (draft, { payload }: PayloadAction<SpotifyTopOptions>) => {
        draft.topArtists.message = '';
        draft.topArtists.status = ASYNC_STATUS.PENDING;
        draft.topArtists.timeRange = payload.time_range;
      },
      prepare: (options: SpotifyTopOptions = { limit: 50, time_range: 'short_term' }) =>
        actionBody(options),
    },
    getTopArtistsSuccess: {
      reducer: (
        draft,
        {
          meta,
          payload,
        }: PayloadAction<SpotifyApi.UsersTopArtistsResponse, string, { updatedAt: number }>,
      ) => {
        draft.topArtists.data = payload.items;
        draft.topArtists.status = ASYNC_STATUS.SUCCESS;
        draft.topArtists.total = payload.total;
        draft.topArtists.updatedAt = meta.updatedAt;
      },
      prepare: (data: SpotifyApi.UsersTopArtistsResponse) => actionBody(data, { updatedAt: now() }),
    },
    getTopArtistsFailure: (draft, { payload }: PayloadAction<string>) => {
      draft.topArtists.message = payload;
      draft.topArtists.status = ASYNC_STATUS.ERROR;
    },
    getTopTracks: {
      reducer: (draft, { payload }: PayloadAction<SpotifyTopOptions>) => {
        draft.topTracks.message = '';
        draft.topTracks.status = ASYNC_STATUS.PENDING;
        draft.topTracks.timeRange = payload.time_range;
      },
      prepare: (options: SpotifyTopOptions = { limit: 50, time_range: 'short_term' }) =>
        actionBody(options),
    },
    getTopTracksSuccess: {
      reducer: (
        draft,
        {
          meta,
          payload,
        }: PayloadAction<SpotifyApi.UsersTopTracksResponse, string, { updatedAt: number }>,
      ) => {
        draft.topTracks.data = payload.items;
        draft.topTracks.status = ASYNC_STATUS.SUCCESS;
        draft.topTracks.total = payload.total;
        draft.topTracks.updatedAt = meta.updatedAt;
      },
      prepare: (data: SpotifyApi.UsersTopTracksResponse) => actionBody(data, { updatedAt: now() }),
    },
    getTopTracksFailure: (draft, { payload }: PayloadAction<string>) => {
      draft.topArtists.message = payload;
      draft.topArtists.status = ASYNC_STATUS.ERROR;
    },
    getTracksStatus: {
      reducer: draft => {
        draft.favorites.message = '';
        draft.favorites.status = ASYNC_STATUS.PENDING;
      },
      prepare: (tracks: string[]) => actionBody(tracks),
    },
    getTracksStatusSuccess: {
      reducer: (draft, { payload }: PayloadAction<string[]>) => {
        draft.favorites.data = mergeFavorites(draft.favorites.data, payload);
        draft.favorites.status = ASYNC_STATUS.SUCCESS;
      },
      prepare: (tracks: string[]) => actionBody(tracks),
    },
    getTracksStatusFailure: (draft, { payload }: PayloadAction<string>) => {
      draft.topArtists.message = payload;
      draft.topArtists.status = ASYNC_STATUS.ERROR;
    },
    removeTrack: {
      reducer: (draft, { payload }: PayloadAction<string>) => {
        draft.removeTracks.push({
          message: '',
          id: payload,
          status: ASYNC_STATUS.PENDING,
        });
      },
      prepare: (id: string) => actionBody(id),
    },
    removeTrackSuccess: {
      reducer: (draft, { payload }: PayloadAction<string>) => {
        draft.removeTracks = draft.removeTracks.map(d =>
          d.id === payload
            ? {
                ...d,
                status: ASYNC_STATUS.SUCCESS,
              }
            : d,
        );
        draft.favorites.data = mergeFavorites(draft.favorites.data, [payload], true);
      },
      prepare: (id: string) => actionBody(id),
    },
    removeTrackFailure: {
      reducer: (draft, { payload }: PayloadAction<string>) => {
        draft.removeTracks = draft.removeTracks.map(d =>
          d.id === payload
            ? {
                ...d,
                message: payload,
                status: ASYNC_STATUS.ERROR,
              }
            : d,
        );
      },
      prepare: (id: string) => actionBody(id),
    },
    resetAudioFeatures: draft => {
      draft.audioFeatures = [];
    },
    saveTrack: {
      reducer: (draft, { payload }: PayloadAction<string>) => {
        draft.saveTracks.push({
          message: '',
          id: payload,
          status: ASYNC_STATUS.PENDING,
        });
      },
      prepare: (id: string) => actionBody(id),
    },
    saveTrackSuccess: {
      reducer: (draft, { payload }: PayloadAction<string>) => {
        draft.saveTracks = draft.saveTracks.map(d =>
          d.id === payload
            ? {
                ...d,
                status: ASYNC_STATUS.SUCCESS,
              }
            : d,
        );
        draft.favorites.data = mergeFavorites(draft.favorites.data, [payload]);
      },
      prepare: (id: string) => actionBody(id),
    },
    saveTrackFailure: {
      reducer: (draft, { payload }: PayloadAction<string>) => {
        draft.saveTracks = draft.saveTracks.map(d =>
          d.id === payload
            ? {
                ...d,
                message: payload,
                status: ASYNC_STATUS.ERROR,
              }
            : d,
        );
      },
      prepare: (id: string) => actionBody(id),
    },
    updateTrackStatus: {
      reducer: (draft, { payload }: PayloadAction<{ id: string; isSaved: boolean }>) => {
        draft.favorites.data = mergeFavorites(draft.favorites.data, [payload.id], !payload.isSaved);
      },
      prepare: (options: { id: string; isSaved: boolean }) => actionBody(options),
    },
  },
});
