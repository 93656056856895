import { ASYNC_STATUS } from '@gilbarbara/helpers';

import { useAppSelector } from '~/hooks/useAppSelector';

import { getTrackUri } from '~/modules/helpers';

import { AudioFeaturesItemWithData } from '~/types';

import {
  alertsSelector,
  breakpointSelector,
  favoritesSelector,
  generatePlaylistSelector,
  generatorSelector,
  isFavoriteSelector,
  librarySelector,
  navigateSelector,
  playerHasTracksSelector,
  playerSelector,
  playlistsGetSelector,
  playlistsSelector,
  spotifySelector,
  topArtistsSelector,
  topTracksSelector,
  userSelector,
} from '../selectors';

export function useAlerts() {
  return useAppSelector(alertsSelector);
}

export function useArtist(uri: string) {
  return useAppSelector(state => {
    return {
      inGenerator: state.app.generator.seed_artists.some(d => d.id === uri),
      isPlaying: state.player.artist?.uri === uri && state.player.isPlaying,
    };
  });
}

export function useBreakpoint() {
  return useAppSelector(breakpointSelector);
}

export function useFavorites() {
  return useAppSelector(favoritesSelector);
}

export function useGeneratePlaylist() {
  return useAppSelector(generatePlaylistSelector);
}

export function useGenerator() {
  return useAppSelector(generatorSelector);
}

export function useAppNavigate() {
  return useAppSelector(navigateSelector);
}

export function useIsFavorite(id: string) {
  return useAppSelector(state => isFavoriteSelector(state, id));
}

export function useLibrary() {
  return useAppSelector(librarySelector);
}

export function usePlayer() {
  return useAppSelector(playerSelector);
}

export function usePlayerHasTracks() {
  return useAppSelector(playerHasTracksSelector);
}

export function usePlaylist() {
  return useAppSelector(playlistsGetSelector);
}

export function usePlaylists() {
  return useAppSelector(playlistsSelector);
}

export function useSpotify() {
  return useAppSelector(spotifySelector);
}

export function useTopArtists() {
  return useAppSelector(topArtistsSelector);
}

export function useTopTracks() {
  return useAppSelector(topTracksSelector);
}

export function useTrack(id: string) {
  return useAppSelector(state => {
    let trackAudioFeaturesWithData;

    const trackAudioFeatures = state.spotify.audioFeatures.find(
      a => a.id === id && a.status === ASYNC_STATUS.SUCCESS,
    );

    if (trackAudioFeatures) {
      trackAudioFeaturesWithData = trackAudioFeatures as AudioFeaturesItemWithData;
    }

    return {
      audioFeatures: trackAudioFeaturesWithData,
      inGenerator: state.app.generator.seed_tracks.some(d => d.id === id),
      isPlaying: getTrackUri(state.player.track).includes(id) && state.player.isPlaying,
      isSaved: state.spotify.favorites.data.includes(id),
      remove: state.spotify.removeTracks.find(item => item.id === id),
      save: state.spotify.saveTracks.find(item => item.id === id),
    };
  });
}

export function useUser() {
  return useAppSelector(userSelector);
}
