import { ReactNode } from 'react';
import { Box, BoxProps, Paragraph } from '@gilbarbara/components';

import Icon from '~/components/Icon';
import Logo from '~/components/Logo';
import { ButtonLink } from '~/components/Navigation';
import PageTitle from '~/components/PageTitle';
import Connect from '~/containers/Connect';

interface Props extends Omit<BoxProps, 'content'> {
  content?: ReactNode;
  iconSize?: number;
  isAuthenticated: boolean;
  showLink?: boolean;
}

function Empty(props: Props) {
  const {
    content = 'Nothing to see here',
    iconSize = 128,
    isAuthenticated,
    showLink,
    ...rest
  } = props;
  let link;
  let icon;

  if (isAuthenticated) {
    if (showLink) {
      link = (
        <ButtonLink to="/generator">
          <Icon name="bolt" space />
          <span>Create Playlist</span>
        </ButtonLink>
      );
    }

    icon = <Icon name="broken-note" size={iconSize} />;
  } else {
    link = <Connect />;
    icon = <Logo file="logo-vertical" />;
  }

  return (
    <Box
      align="center"
      data-component-name="Empty"
      direction="column"
      flex
      flexBox
      justify="center"
      {...rest}
    >
      {icon}
      <PageTitle mb={0} mt="xl">
        Ooops
      </PageTitle>
      <Paragraph mb="xl">{content}</Paragraph>
      {link}
    </Box>
  );
}

export default Empty;
