import { Button, NonIdealState } from '@gilbarbara/components';

interface Props {
  error: Error;
  resetErrorBoundary: () => void;
}

export default function ErrorHandler({ error, resetErrorBoundary }: Props) {
  const handleClickReset = () => {
    resetErrorBoundary();
  };

  return (
    <NonIdealState description={error.message} type="error">
      <Button onClick={handleClickReset} variant="red">
        Try again
      </Button>
    </NonIdealState>
  );
}
